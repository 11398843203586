import { Dialog } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from '../../../components';
import { getColorContrast } from '../../../utils/helpers';

export const NotFoundDialog = (props) => {
    const {open, onClose, clientThemeSettings} = props

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={onClose} 
            keepMounted={false}
        >
            <div className="no-border-container-center background-gray" style={{ width:"100%", display:"flex", flexDirection:"row", padding:"5px", margin:"0", justifyContent:"space-evenly", backgroundColor: clientThemeSettings.background_color }}>
                <div style={{ display:"flex", flexDirection:"column", margin:"0px 53px"}}>
                    <h1 className="subtitle-level4 gray" style={{ color: getColorContrast(clientThemeSettings.background_color) }}>
                        ERRO
                    </h1>
                </div>
                <IconButton 
                    onClick={onClose}
                    style={{position: "absolute", right:"2px", width: "24px", height: "24px"}}
                >
                    <CloseIcon style={{ color: getColorContrast(clientThemeSettings.background_color) }} />
                </IconButton>
            </div>
            <div className="no-border-container-margin" style={{textAlign:"center"}}>
                Dados incorretos.
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 2rem 1rem' }}>
                <Button
                    clientThemeSettings={clientThemeSettings}
                    onClick={onClose}
                >
                    CONTINUAR
                </Button>
            </div>
        </Dialog>
    );
}