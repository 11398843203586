import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { Wallpaper } from '../../components/Wallpaper'
import { LoadingPage } from '../LoadingPage'
import { QRCodeSVG } from 'qrcode.react'
import { ErrorPage } from '../ErrorPage'
import {
    CollectImageTitle
} from '../PersonalDocument/styles'
import { Logo } from '../../components'
import { Footer } from "../../components/Footer"
import { Box } from '@mui/material'
import {
    isMobile
} from '../../utils/utils'

export const FaceRecognitionBatch = (props) => {

    const { clientThemeSettings, signBatchData, setSignerBatchStep, setSignBatchData } = props
    const [loading, setLoading] = useState(false)
    const faceReconRef = useRef(null)

    const initialError = {
        "has_error": false,
        "status": 0
    }
    const [error, setError] = useState(initialError)
    
    const [hasMadeRequest, setHasMadeRequest] = useState(false)

    const sendMessageToIframe = () => {
        const faceReconElement = faceReconRef.current
        faceReconElement.contentWindow.postMessage({
            face_token: signBatchData.face_token,
            session_id: signBatchData.session_id,
            environment: process.env.REACT_APP_ENV,
            themeConfiguration: {
                "buttonColor": clientThemeSettings["button_color"],
                "fontColor": clientThemeSettings["font_color"],
                "backgroundColor": clientThemeSettings["background_color"]
            }

        }, '*')
    }

    const qrCodeUrl = signBatchData.signer_batch_data.birthdate ? `${window.location.href}?document_number=${signBatchData.signer_batch_data.document_number}&birthdate=${signBatchData.signer_batch_data.birthdate?.split('-').reverse().join('-')}` : `${window.location.href}?document_number=${signBatchData.signer_batch_data.document_number}`

    useEffect(() => {

        const faceReconElement = faceReconRef.current
        const iframeLoadListener = () => {
            sendMessageToIframe()
        }
        faceReconElement?.addEventListener('load', iframeLoadListener)
        const handleMessage = (event) => {
            if (event.data.type === 'faceRecognitionResult') {

                if (hasMadeRequest) {
                    return
                }
                                
                const imageKey = (event.data.data)
                setLoading(true)
                const data = {
                    "event_date": new Date().toISOString(),
                    "image_key": imageKey,
                    "step": "face_recognition"
                }
                const config = {
                    headers:
                    {
                        'Authorization': signBatchData.signer_token
                    }
                }

                setHasMadeRequest(true)
                axios.patch('/signer_batch', data, config)
                    .then(response => {
                        setSignBatchData({ ...signBatchData, ...response.data })
                        setSignerBatchStep(response.data.next_step)
                    })
                    .catch((error) => {
                        if ([401, 403].includes((error.response || {}).status)) {
                            setError({
                                "has_error": true,
                                "status": ((error.response || {}).status || 500)
                            })
                        } else {
                            setError({
                                "has_error": true,
                                "status": 500
                            })
                        }
                        setHasMadeRequest(false)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }

        window.addEventListener('message', handleMessage)

        return () => {
            window.removeEventListener('message', handleMessage)
        }
    })

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    if (!isMobile()) {
        return (
            <Wallpaper
                backgroundColor={clientThemeSettings["background_color"]}
                style={{ padding: '10px' }}
            >
                <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                    <Logo imageSource={clientThemeSettings.logo_url} />
                    <Box>
                        <CollectImageTitle>
                            Agora precisamos realizar a prova de vida. Escaneie o QR Code abaixo para continuar a jornada pelo seu dispositivo móvel.
                        </CollectImageTitle>
                        <div style={{display: 'flex'}}>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', height: '100%'}}>
                                <QRCodeSVG value={qrCodeUrl} width={'70%'} height={'70%'} />
                            </div>
                        </div>               
                    </Box>
                    <Footer />
                </div>
            </Wallpaper>
        )
    }

    return (
        <Wallpaper
            id="faceReconContainer"
            backgroundColor={clientThemeSettings["background_color"]}
        >    
            <iframe
                id="faceRecognition"
                ref={faceReconRef}
                title="Face Recognition"
                src="/faceRecognition.html"
                style={{ border: 'none', boxShadow: 'none', width: '100%', height: '100%' }}
            />
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerBatchStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />}
        </Wallpaper>
    )
}
