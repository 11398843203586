import React, { useState, useCallback } from 'react'
import { useEffect } from 'react'
import axios from 'axios'

import { ErrorPage } from "../ErrorPage"
import { LoadingPage } from '../LoadingPage'
import { Wallpaper } from '../../components/Wallpaper'
import { RetryDeviceScanDialog } from '../Login/RetryDeviceScanDialog'

export const DeviceScan = (props) => {

    const { clientThemeSettings, signData, setSignerStep, setSignData, isBatch } = props
    const [loading, setLoading] = useState(true)
    const [deviceScanHasMadeRequest, setDeviceScanHasMadeRequest] = useState(false);
    const eventType = "QiSign"
    const eventId   = "1"

    const endpoint = isBatch ? '/signer_batch' : '/signer'

    const initialError = {
        "has_error": false,
        "status": 0
    }

    const [error, setError] = useState(initialError)
    const [openRetryDeviceScanDialog, setOpenRetryDeviceScanDialog] = useState(false)

    const sendDeviceScanRequest = useCallback(async () => {

        if (!signData.signer_token || deviceScanHasMadeRequest) {
            return;
        }
    
        if (!window.vPkg?.DeviceScan) {
            setOpenRetryDeviceScanDialog(true)
        }
    
        var deviceScan = process.env.REACT_APP_ENV === 'production' ? new window.vPkg.DeviceScan(signData.device_token, signData.session_id) : new window.vPkg.DeviceScan(signData.device_token, signData.session_id).setSandbox()
    
        async function callDeviceScan(eventType, eventId) {
            await deviceScan.info(eventType, eventId).then(() => {
                setOpenRetryDeviceScanDialog(false)
            }).catch(() => {
                setOpenRetryDeviceScanDialog(true)
                throw new Error("Failed Device Scan")
            })
        }
    
        try {
            await callDeviceScan(eventType, eventId)
        } catch (error) {
            return
        }
    
        const data = {
            "event_date": new Date().toISOString(),
            "step": "device_scan",
            "session_id": signData.session_id
        };
    
        const config = {
            headers: {
                'Authorization': signData.signer_token
            }
        };
    
        try {
            const response = await axios.patch(endpoint, data, config);
            setSignerStep(response.data.next_step);
            setSignData({ ...signData, ...response.data})
            setDeviceScanHasMadeRequest(true)
        } catch (error) {
            if (setError !== null) {
                if ([401, 403].includes((error.response || {}).status)) {
                    setError({
                        "has_error": true,
                        "status": error.response.status
                    });
                } else {
                    setError({
                        "has_error": true,
                        "status": 500
                    });
                }
            }
        } finally {
            setLoading(false);
        }
    }, [signData, deviceScanHasMadeRequest, setDeviceScanHasMadeRequest, setSignerStep, setSignData, setError, endpoint])

    useEffect(() => {
        sendDeviceScanRequest()
    }, [sendDeviceScanRequest])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    return (
        <Wallpaper
            id="deviceScanContainer"
            backgroundColor={clientThemeSettings["background_color"]}
        >    
            {error.has_error &&
                <ErrorPage
                    error={error}
                    redirectToLogin={() => setSignerStep("login")}
                    resetError={() => setError(initialError)}
                    clientThemeSettings={clientThemeSettings}
                />}
            {openRetryDeviceScanDialog &&
            <RetryDeviceScanDialog
                open={openRetryDeviceScanDialog}
                retryDeviceScan={sendDeviceScanRequest}
                clientThemeSettings={clientThemeSettings}
            />}
        </Wallpaper>
    )
}
