import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { Wallpaper } from '../../components/Wallpaper'
import { Footer } from '../../components/Footer'
import { LoadingPage } from '../LoadingPage'
import { formatPhone } from '../../utils/utils'
import { OTPInput } from './OTPInput'
import { Button, Logo } from '../../components'


export const OTPPageBatch = (props) => {

    const { clientThemeSettings, signBatchData, onSendOTPCode, setSignerBatchStep} = props

    const submissionMethod = ((signBatchData || {}).signer_batch_data || {}).authentication_submission_method
    const email = ((signBatchData || {}).signer_batch_data.email || null)
    const phone = ((signBatchData || {}).signer_batch_data.phone ? signBatchData.signer_batch_data.phone.international_dial_code + signBatchData.signer_batch_data.phone.area_code + signBatchData.signer_batch_data.phone.number : null)

    const [loading, setLoading] = useState(false)
    const initialError = {
        "has_error": false,
        "status": 0
    }
    const [error, setError] = useState(initialError)

    const [otpInput, setOTPInput] = useState({ "otp1": "", "otp2": "", "otp3": "", "otp4": "", "otp5": "", "otp6": "" })

    const onConfirmOTPCode = () => {
        let otpConcatenated = ""
        for (let i = 1; i < 7; i++) {
            otpConcatenated += otpInput[`otp${i}`]
        }

        const data = {
            "authentication_code": otpConcatenated,
        }
        const config = {
            headers:
            {
                'Authorization': signBatchData.signer_token
            }
        }
        setLoading(true)
        axios
            .patch('/signer_batch/authentication', data, config)
            .then((response) => {
                setSignerBatchStep("signed")
                setLoading(false)
            }).catch((error) => {
                if ([401, 403].includes((error.response || {}).status)) {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                } else {
                    setError({
                        "has_error": true,
                        "status": 500
                    })
                }
                setLoading(false)
            })
    }

    const [isBlocked, setIsBlocked] = useState(true)
    const [remainingTime, setRemainingTime] = useState(60)

    const handleSendOTPCode = useCallback(async () => {
        if (isBlocked) return
        setIsBlocked(true)
        setRemainingTime(90)
        onSendOTPCode(signBatchData.signer_token)

    }, [isBlocked, onSendOTPCode, signBatchData.signer_token])

    useEffect(() => {
        let interval
        if (isBlocked) {
            interval = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1)
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    }, [isBlocked, handleSendOTPCode])

    useEffect(() => {
        if (remainingTime === 0) {
            setIsBlocked(false)
        }
    }, [remainingTime])

    if (loading) {
        return (
            <LoadingPage clientThemeSettings={clientThemeSettings} />
        )
    }

    return (
        <Wallpaper
            backgroundColor={clientThemeSettings.background_color}
        >
            <div className="base-container base-container-width base-container-padding" style={{ backgroundColor: "rgb(245, 245, 242)" }}>
                <Logo imageSource={clientThemeSettings.logo_url} />

                <div className="no-border-container-fullwidth description-level4 gray" style={{ textAlign: "center" }}>
                    <p style={{ display: "inline", margin: "5px", padding: "0" }}>
                        <span className="bold">Código de autenticação </span>
                        <span>enviado para </span>
                        <span className="bold">{submissionMethod === 'email' ? email : formatPhone(phone)}</span>
                    </p>
                    <p style={{ margin: "5px", padding: "0" }}>
                        Confirme o código para finalizar:
                    </p>
                </div>
                <div className="no-border-container-fullwidth">
                    <OTPInput
                        error={error}
                        otpInput={otpInput}
                        setOTPInput={setOTPInput}
                    />
                    {error.has_error &&
                        <p className={"form-error"} style={{ margin: "10px auto" }}>
                            O código não confere com o enviado.
                        </p>}
                    <div className="otp__notification-container">
                        <div>Deseja reenviar o código?</div>
                        <div
                            onClick={handleSendOTPCode}
                            className="otp__notification-link link"
                            disabled={isBlocked}
                        >
                            <span>{isBlocked ? 'Bloqueado por ' : 'Reenviar '}</span>
                            {isBlocked && <span className="bold">{`${remainingTime}s`}</span>}
                        </div>
                    </div>
                </div>
                <Button
                    onClick={() => (Object.values(otpInput).filter((value) => (value === "")).length === 0) ? onConfirmOTPCode() : null}
                    clientThemeSettings={clientThemeSettings}
                >
                    CONFIRMAR
                </Button>
                <Footer />
            </div>
        </Wallpaper>
    )
}