import { Dialog } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from '../../../components';
import { getColorContrast } from '../../../utils/helpers';

export const RetryDeviceScanDialog = (props) => {
    const {open, retryDeviceScan, clientThemeSettings} = props

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            keepMounted={false}
        >
            <div className="no-border-container-center background-gray" style={{ width:"100%", display:"flex", flexDirection:"row", padding:"5px", margin:"0", justifyContent:"space-evenly", backgroundColor: clientThemeSettings.background_color }}>
                <div style={{ display:"flex", flexDirection:"column", margin:"0px 53px"}}>
                    <h1 className="subtitle-level4 gray" style={{ color: getColorContrast(clientThemeSettings.background_color) }}>
                        ERRO
                    </h1>
                </div>
                <div style={{ position: "absolute", right: "5px", top: "5px" }}>
                    <IconButton 
                        onClick={retryDeviceScan}
                        style={{ padding: 0 }}
                    >
                        <CloseIcon style={{ color: getColorContrast(clientThemeSettings.background_color) }} />
                    </IconButton>
                </div>
            </div>
            <div className="no-border-container-margin" style={{textAlign:"center"}}>
                Não foi possível processar algumas informações. Por favor, tente novamente.
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 2rem 1rem' }}>
                <Button
                    clientThemeSettings={clientThemeSettings}
                    onClick={retryDeviceScan}
                >
                    TENTAR NOVAMENTE
                </Button>
            </div>
        </Dialog>
    );
}